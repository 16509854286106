import m from "mithril";

const createObjectURL = (img) => {
    const URL = window.URL || window.webkitURL || window.mozURL || window.msURL;
    return URL.createObjectURL(img);
}

const uploader = {
    uploading: false,
    imgSrc: null,
    url: '',
    progress: null,
    dragdrop: (element, options) => {
        options = options || {}

        element.addEventListener("dragover", activate)
        element.addEventListener("dragleave", deactivate)
        element.addEventListener("dragend", deactivate)
        element.addEventListener("drop", deactivate)
        element.addEventListener("drop", update)

        function activate(e) {
            e.preventDefault()
        }

        function deactivate() { }

        function update(e) {
            e.preventDefault()
            if (typeof options.onchange == "function") {
                options.onchange((e.dataTransfer || e.target).files);
            }
        }
    },
    uploadToServer: (url, file, realfilename) => {
        let formData = new FormData;
        // formData.append("upload", file)
        formData.append('file', file)
        formData.append('type', "photo")
        formData.append('width', 1440)
        formData.append('height', 400)
        formData.append('folder', "banners")
        if (realfilename) {
            formData.append("realfilename", realfilename)
        }
        return m.request({
            method: 'POST',
            url: url,
            data: formData,
            config: function (xhr) {
                xhr.upload.addEventListener("progress", function (e) {
                    uploader.progress = Math.round(e.loaded / e.total * 100)
                    //m.redraw()
                }, true)
            }
        })
    },
    filterExtension: (files) => {
        for (let i = 0; i < files.length; i++) {
            if (/^image/.test(files[i].type)) {
                return true;
            }
        }
        return false;
    },
    filterSize: (files) => {
        for (let i = 0; i < files.length; i++) {
            if (files[i].size > 4194304) {
                return true;
            }
        }
        return false;
    },
    draw: (file, options) => {
        const reader = new FileReader();
        reader.readAsDataURL(file)

        return new Promise((resolve, reject) => {
            reader.onload = function (e) {
                let img = new Image()
                img.src = e.target.result
                img.onload = () => {
                    let canvas = uploader.resizeCrop(img, options.width, options.height).toDataURL('image/jpg', 90);
                    uploader.imgSrc = canvas
                    m.redraw()
                }
            }

            reader.onloadend = (e) => {
                resolve('done')
            }
        })
    },
    upload: (file, options) => {
        let formData = new FormData;
        uploader.uploading = true

        const reader = new FileReader();
        reader.readAsDataURL(file)

        let promise = new Promise((resolve, reject) => {
            reader.onload = function (e) {
                let img = new Image()
                img.src = e.target.result
                img.onload = () => {
                    let canvas = uploader.resizeCrop(img, options.width, options.height).toDataURL('image/jpg', 90);
                    uploader.imgSrc = canvas
                    m.redraw()
                }
            }

            reader.onloadend = (e) => {
                resolve('good')
            }
        })
        return promise.then(() => {
            return uploader.uploadToServer(options.url, file, options.uuid).then((data) => {
                uploader.progress = null
                uploader.uploading = false
                return data
            })
        })
    },
    resizeCrop: (src, width, height) => {
        let crop = true
        // not resize
        if (width > src.width || height > src.height) {
            width = src.width;
            height = src.height;
            // crop = false
        }

        // check scale
        var xscale = width / src.width;
        var yscale = height / src.height;
        var scale = crop ? Math.max(xscale, yscale) : Math.min(xscale, yscale);
        // create empty canvas
        var canvas = document.createElement("canvas");
        canvas.width = width ? width : Math.round(src.width * scale);
        canvas.height = height ? height : Math.round(src.height * scale);
        canvas.getContext("2d").scale(scale, scale);
        // crop it top center
        canvas.getContext("2d").drawImage(src, ((src.width * scale) - canvas.width) * -.5, ((src.height * scale) - canvas.height) * -.5);
        return canvas;
    }
}

export default uploader;