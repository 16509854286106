import m from 'mithril'
import * as Page from './pages'
import isMobile from './lib/detect-mobile'


const root = document.body
window.language = 'tw'

window.isMobile = isMobile()

const routes = {
    '/': Page.DashboardPage,
    "/:404...": Page.ErrorPage
}

export function render() {
    const loader = document.getElementById('loader');
    
    loader.classList.add('fadeOut');
    m.route(root, '/', routes)
}