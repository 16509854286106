import m from 'mithril'
import stream from 'mithril/stream'
import moment from 'moment'
import {
    Dialog,
    TextBox,
    TextArea,
} from '@base/app/components'

import {
    PhotoUploader,
} from '@base/app/components/uploader'



class EditComponent {
    constructor(vnode) {
        this.model = vnode.attrs.model
        this.cities = vnode.attrs.cities
        this.types = vnode.attrs.types
        this.methods = vnode.attrs.methods

        this.preview = stream("")
        this.previewtwo = stream("")
        if (this.model.id != 0) {
            this.preview(this.model.photo)
            this.previewtwo(this.model.phototwo)
        }

        this.done_upload = stream(null)
    }
    save() {
        if (this.done_upload() != null){
            console.log("asdjkio")
            return
        }

        this.model.photo = this.preview()
        this.model.phototwo = this.previewtwo()

        this.model.save().then((response) => {
            swal({
                title: (this.model.id == 0) ? "新增成功" : "編輯成功!",
                text: (this.model.id == 0) ? "已成功新增資訊，請至列表查看!" : "已成功編輯資訊，請至列表查看!!",
                icon: "success",
                buttons: false,
                timer: 1800,
            })
            Dialog.close(true)
        })
    }

    getPhotoPath(filename) {
        if (filename == undefined || filename == null || filename == '') {
            return `${window.BASE_URL}/images/boards/250_150.jpg`
        }
        return `${window.BASE_URL}/images/boards/${filename}`
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view() {
        return [m(".modal-dialog[role='document'].modal-lg.modal-dialog-centered", [
            m(".modal-content",
                [
                    m(".modal-header",
                        [
                            m("h5.modal-title", ((this.model.id == 0) ? "新增" : "編輯")),
                            m("button.close", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                m("span", m.trust("&times;"))
                            ),
                        ]
                    ),
                    m(".modal-body",
                        m('.row.m-0', [
                            m('.col-lg-12', [
                                m(".row.no-gutters", [
                                    m('.col-lg-6', [
                                        m("span", "帆布圖片"),
                                        m(PhotoUploader, {
                                            caption: '視覺圖 250*150',
                                            path: this.getPhotoPath(this.model.photo),
                                            url: `${window.BASE_URL}/api/upload/photo`,
                                            preview: true,
                                            width: 250,
                                            height: 150,
                                            viewW: (window.isMobile) ? 310 : 380,
                                            viewH: (window.isMobile) ? 180 : 240,
                                            filename: this.preview,
                                            folder: "boards",
                                            progress: this.done_upload
                                        })
                                    ]),
                                    m('.col-lg-6', [
                                        m("span", "第二次圖片"),
                                        m(PhotoUploader, {
                                            caption: '視覺圖 250*150',
                                            path: this.getPhotoPath(this.model.phototwo),
                                            url: `${window.BASE_URL}/api/upload/photo`,
                                            preview: true,
                                            width: 250,
                                            height: 150,
                                            viewW: (window.isMobile) ? 310 : 380,
                                            viewH: (window.isMobile) ? 180 : 240,
                                            filename: this.previewtwo,
                                            folder: "boards",
                                            progress: this.done_upload
                                        })
                                    ]),

                                    (this.preview() != "" )?
                                    m('.col-lg-6.col-12.px-2', [
                                        m("button.btn.btn-primary.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button'].border-0.w-100", {
                                            style: {
                                                background: "#386E21",
                                                color: "#FFFFFF"
                                            },
                                            onclick: (e) => {
                                                e.preventDefault()
                                                this.model.photo = ""
                                                this.preview(this.model.photo)
                                            }
                                        },
                                            [
                                                m("span","清除第一張照片"),

                                            ]
                                        ),
                                    ]):"",
                                    (this.previewtwo() != "") ?
                                        m('.col-lg-6.col-12.px-2', [
                                            m("button.btn.btn-primary.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button'].border-0.w-100", {
                                                style: {
                                                    background: "#386E21",
                                                    color: "#FFFFFF"
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    this.model.phototwo = ""
                                                    this.previewtwo(this.model.phototwo)
                                                }
                                            },
                                                [
                                                    m("span", "清除第二張照片"),

                                                ]
                                            ),
                                        ]) : "",
                                    m('.col-lg-4.col-12', [
                                        m(".row.no-gutters", [
                                            m('.col-lg-12', [
                                                m("span", "帆布製作尺寸(H*WCM)")
                                            ]),
                                            m('.col-lg-4.col-5', [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.height = e.target.value
                                                    },
                                                    class: 'mt-0 form-control text-center',
                                                    value: this.model.height,
                                                    type: 'number',
                                                }),
                                            ]),
                                            m('.col-lg-2.col-2', [
                                                m(".d-flex.align-items-center.justify-content-center.h-100", [
                                                    m("span", "*")
                                                ])
                                            ]),
                                            m('.col-lg-4.col-5', [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.width = e.target.value
                                                    },
                                                    class: 'mt-0 form-control text-center',
                                                    value: this.model.width,
                                                    type: 'number',
                                                }),
                                            ]),
                                            m('.col-lg-4.pr-1', [
                                                m(".form-group", [
                                                    m("label.control-label.mb-1", "方式"),
                                                    m("select.form-control", {
                                                        onchange: (e) => {
                                                            this.model.method = e.target.value
                                                        }
                                                    },
                                                        m("option"),
                                                        this.methods.map((item) => {
                                                            return m("option", {
                                                                value: item,
                                                                selected: this.model.method == item
                                                            }, item)
                                                        })
                                                    ),

                                                ])
                                            ]),
                                            m('.col-lg-4.pr-1', [
                                                m(".form-group", [
                                                    m("label.control-label.mb-1", "廠商"),
                                                    m("select.form-control", {
                                                        onchange: (e) => {
                                                            this.model.company = e.target.value
                                                        }
                                                    },
                                                        m("option"),
                                                        ["","華宇博","優是","無"].map((item) => {
                                                            return m("option", {
                                                                value: item,
                                                                selected: this.model.company == item
                                                            }, item)
                                                        })
                                                    ),

                                                ])
                                            ]),


                                            // m('.col-lg-12', [
                                            //     m(".form-group", [
                                            //         m("label.control-label.mb-1", "方式"),
                                            //         m("select.form-control", {
                                            //             onchange: (e) => {
                                            //                 this.model.method = e.target.value
                                            //             }
                                            //         },
                                            //             m("option"),
                                            //             this.methods.map((item) => {
                                            //                 return m("option", {
                                            //                     value: item,
                                            //                     selected: this.model.method == item
                                            //                 }, item)
                                            //             })
                                            //         ),

                                            //     ])
                                            // ]),
                                            m('.col-lg-6', [
                                                m(".form-radio.form-radio-flat.my-2",
                                                    m("label.form-check-label.pl-4",
                                                        [
                                                            m("input.form-check-input[type='radio']", {
                                                                checked: this.model.is_car,
                                                                style: "color:#000",
                                                                onclick: () => {
                                                                    this.model.is_car = !this.model.is_car
                                                                }
                                                            }),
                                                            "吊車",
                                                            m("i.input-helper")
                                                        ]
                                                    )
                                                ),
                                            ]),
                                            m('.col-lg-6', [
                                                m(".form-radio.form-radio-flat.my-2",
                                                    m("label.form-check-label.pl-4",
                                                        [
                                                            m("input.form-check-input[type='radio']", {
                                                                checked: this.model.is_eagle,
                                                                style: "color:#000",
                                                                onclick: () => {
                                                                    this.model.is_eagle = !this.model.is_eagle
                                                                }
                                                            }),
                                                            "鷹架",
                                                            m("i.input-helper")
                                                        ]
                                                    )
                                                ),
                                            ]),
                                            m('.col-lg-6', [
                                                m(".form-radio.form-radio-flat.my-2",
                                                    m("label.form-check-label.pl-4",
                                                        [
                                                            m("input.form-check-input[type='radio']", {
                                                                checked: this.model.is_paid_one,
                                                                style: "color:#000",
                                                                onclick: () => {
                                                                    this.model.is_paid_one = !this.model.is_paid_one
                                                                }
                                                            }),
                                                            "第一次付款",
                                                            m("i.input-helper")
                                                        ]
                                                    )
                                                ),
                                            ]),
                                            m('.col-lg-6', [
                                                m(".form-radio.form-radio-flat.my-2",
                                                    m("label.form-check-label.pl-4",
                                                        [
                                                            m("input.form-check-input[type='radio']", {
                                                                checked: this.model.is_paid_two,
                                                                style: "color:#000",
                                                                onclick: () => {
                                                                    this.model.is_paid_two = !this.model.is_paid_two
                                                                }
                                                            }),
                                                            "第二次付款",
                                                            m("i.input-helper")
                                                        ]
                                                    )
                                                ),
                                            ]),
                                        ]),
                                    ]),
                                ])
                            ]),
                            m('.col-lg-2', [
                                m(".form-group", [
                                    m("label.control-label.mb-1", "類型"),
                                    m("select.form-control", {
                                        onchange: (e) => {
                                            this.model.type = e.target.value
                                        }
                                    },
                                        m("option"),
                                        this.types.map((item) => {
                                            return m("option", {
                                                value: item,
                                                selected: this.model.type == item
                                            }, item)
                                        })
                                    ),

                                ])
                            ]),
                            m('.col-lg-2', [
                                m(".form-group", [
                                    m("label.control-label.mb-1", "地區"),
                                    m("select.form-control", {
                                        onchange: (e) => {
                                            this.model.city = e.target.value
                                        }
                                    },
                                        m("option"),
                                        this.cities.map((item) => {
                                            return m("option", {
                                                value: item,
                                                selected: this.model.city == item
                                            }, item)
                                        })
                                    ),

                                ])
                            ]),
                            m('.col-lg-6', [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.address = e.target.value
                                    },
                                    value: this.model.address,
                                    type: 'text',
                                    label: '地址',
                                })
                            ]),
                            m('.col-lg-2', [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.subtitle = e.target.value
                                    },
                                    value: this.model.subtitle,
                                    type: 'text',
                                    label: '地址備註',
                                })
                            ]),

                            m('.col-lg-3', [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.contact = e.target.value
                                    },
                                    value: this.model.contact,
                                    type: 'text',
                                    label: '窗口',
                                })
                            ]),
                            m('.col-lg-3', [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.phone = e.target.value
                                    },
                                    value: this.model.phone,
                                    type: 'text',
                                    label: '連絡電話',
                                })
                            ]),
                            m('.col-lg-3', [
                                m("label.control-label.mb-1", "租金"),
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.rent = e.target.value
                                    },
                                    class: 'mt-0 form-control text-center',
                                    value: this.model.rent,
                                    type: 'number',
                                }),
                            ]),

                            m('.col-lg-3', [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.memo = e.target.value
                                    },
                                    value: this.model.memo,
                                    type: 'text',
                                    label: '備註',
                                })
                            ]),

                            m('.col-lg-12', [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.title = e.target.value
                                    },
                                    value: this.model.title,
                                    type: 'text',
                                    label: '版面內容',
                                })
                            ]),

                        ]),
                    ),
                    m(".modal-footer",
                        [
                            m("button.btn.btn-label.rounded", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                [
                                    m("i.fa.fa-times-circle"),
                                    "取消"
                                ]
                            ),
                            m("button.btn.btn-primary[type='button']", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.save()
                                }
                            },
                                [
                                    m("i.fa.fa-check-circle"),
                                    "儲存"
                                ]
                            )
                        ]
                    )
                ]
            )
        ])]
    }
}

export default EditComponent