import m from 'mithril'
import Uploader from './core'

class PhotoUploader {
    constructor(vnode) {
        this.uploader = new Uploader()
        this.filename = vnode.attrs.filename
        this.isDisabled = vnode.attrs.disabled
        this.progress = vnode.attrs.progress
    }
    view(vnode) {
        return m('figure', [
            m('.g-preivew-container.mx-auto', {
                style: {
                    minWidth: vnode.attrs.viewW + "px" ,
                    minHeight: vnode.attrs.viewH + "px" ,
                    width: "min-content",
                    height: "min-content",
                },
                onclick: (e) => {
                    vnode.dom.querySelector('.g-file_browser').click()
                }
            }, [
                    // m('.g-preivew-content.d-inline-block.mx-auto.border.rounded.p-1.position-relative',{
                    //     style: {
                    //         backgroundImage: `url("${this.uploader.imgSrc() ? this.uploader.imgSrc() : vnode.attrs.path}")`,
                    //         backgroundRepeat: "no-repeat",
                    //         backgroundPosition: "center",
                    //         backgroundSize:"cover",
                    //         width: vnode.attrs.viewW + "px" ,
                    //         height: vnode.attrs.viewH + "px" ,
                    //     },
                    // }, [
                    //     // m('img.h-100', {
                    //         // src: this.uploader.imgSrc() ? this.uploader.imgSrc() : vnode.attrs.path
                    //     // }),
                    //     m('.g-preivew-mask'),
                    //     m('.d-flex.justify-content-center.align-items-end.position-absolute', {
                    //         style: {
                    //             top: 0,
                    //             left: 0,
                    //             right: 0,
                    //             bottom: 0,
                                
                    //         }
                    //     }, [
                    //         (vnode.attrs.progress()!= null) ? [
                    //                 m('.progress.mb-0.w-100', [
                    //                     m('.determinate', {
                    //                         style: {
                    //                             width: `${vnode.attrs.progress()}%`,
                    //                             backgroundColor: (vnode.attrs.progress() == 0) ? "rgba(255,255,255,0.001)" : "rgba(255,0,0,1)"
                    //                         }
                    //                     })
                    //                 ])
                    //             ] : ""
                    //         ])
                    // ]),

                m('img.g-preivew-content.d-inline-block.mx-auto.border.rounded.p-1.position-relative.w-100', {
                    src: `${this.uploader.imgSrc() ? this.uploader.imgSrc() : vnode.attrs.path}`,
                    style:{}
                    // style: {
                    //     backgroundImage: `url("${this.uploader.imgSrc() ? this.uploader.imgSrc() : vnode.attrs.path}")`,
                    //     backgroundRepeat: "no-repeat",
                    //     backgroundPosition: "center",
                    //     backgroundSize: "cover",
                    //     width: vnode.attrs.viewW + "px",
                    //     height: vnode.attrs.viewH + "px",
                    // },
                }, [
                    // m('img.h-100', {
                    // src: this.uploader.imgSrc() ? this.uploader.imgSrc() : vnode.attrs.path
                    // }),
                    m('.g-preivew-mask'),
                    m('.d-flex.justify-content-center.align-items-end.position-absolute', {
                        style: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,

                        }
                    }, [
                        (vnode.attrs.progress() != null) ? [
                            m('.progress.mb-0.w-100', [
                                m('.determinate', {
                                    style: {
                                        width: `${vnode.attrs.progress()}%`,
                                        backgroundColor: (vnode.attrs.progress() == 0) ? "rgba(255,255,255,0.001)" : "rgba(255,0,0,1)"
                                    }
                                })
                            ])
                        ] : ""
                    ])
                ]),
                    m('input.g-file_browser[type="file"][name="files[]"][accept="image/*"]', {
                        onchange: (e) => {
                            // if (vnode.attrs.isDisabled && !this.isDisabled()) {
                            //     return false
                            // }
                            if (e.target.files.length === 0) {
                                return false
                            }
                            if (!this.uploader.filterImageExtension(e.target.files)) {
                                alert('不支援上傳的檔案格式, 請選擇圖片檔!')
                                return false
                            }
                            this.uploader.uploadImage(e.target.files[0], {
                                url: vnode.attrs.url,
                                width: vnode.attrs.width,
                                height: vnode.attrs.height,
                                folder: vnode.attrs.folder,
                                type: "photo",
                                preview: vnode.attrs.preview,
                                progress:vnode.attrs.progress
                            }).then((response) => {
                                this.filename(response.filename)
                            })
                        }
                    })
                ]),
            (vnode.attrs.caption) ? [
                m('figcaption.figure-caption.py-1.text-center', [
                    vnode.attrs.caption
                ])
            ] : ''
        ])
    }
}

export default PhotoUploader