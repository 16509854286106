import m from 'mithril'

export default class ErrorPage {
    view() {
        return m('.container.h-100', [
            m('.row.h-100', [
                m('.col-12.h-100', [
                    m('.d-flex.h-100.justify-content-center.align-items-center.flex-column', [
                        m('h1.display-1', [
                            '404'
                        ]),
                        m('h6', [
                            '找不到頁面'
                        ])
                    ])
                ])
            ])
        ])
    }
}