import m from 'mithril'
import {
    Dialog
} from '@base/app/components'

class Snackbar {
    constructor(vnode) {
        this.position = vnode.attrs.position ? vnode.attrs.position : 'bottom-center'
    }
    oncreate(vnode) {
        setTimeout(() => {
            vnode.dom.classList.add("show")
        }, 200)

        setTimeout(() => {
            vnode.dom.classList.remove('show')
            setTimeout(() => {
                Dialog.close(false, vnode.attrs.key)
                m.redraw()
            }, 250)
        }, 3000)
    }
    view(vnode) {
        return m('.g-snackbar.shadow', {
            class: this.position
        }, [
            m('.g-snackbar-content', [
                vnode.children
            ])
        ])
    }
}

export default Snackbar