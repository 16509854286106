import m from 'mithril'
import classNames from 'classnames'
import anime from 'animejs/lib/anime.es.js';
import moment from 'moment'
import stream from 'mithril/stream'
import {
    Board,
} from '@base/app/models'
import {
    Dialog,
    Paging,
    TextBox,
} from '@base/app/components'

import EditComponent from './edit'
import PhotoComponent from './photo'



export default class DashboardPage {
    constructor() {
        Board.pageIndex = 1
        Board.pageSize = 12
        this.foucus = false
        this.cities = ["土城區", "三峽區", "板橋區", "樹林區", "鶯歌區", "中和區"]
        this.types = ["地址", "交叉口"]
        this.methods = ["牆面", "鐵架", "搭架", "鐵架(固定)", "鐵架(已拆)"]

        window.search_word = ""
        window.select_cate = ""

        this.upBoder = 0

        this.results = []

        Board.fetch().then((_response) => {
            this.results = _response
            this.upBoder = 0
            this.results.map((_data) => {
                if (_data.photo == "" || _data.photo == null) {

                } else {
                    this.upBoder += 1
                }
            })
        })

        this.click_index = 9999

        this.open = false
    }
    oncreate() {
        anime({
            targets: '.text-card-play',
            translateY: [-250, 0],
            direction: 'alternate',
            loop: false,
            delay: 100,
            endDelay: 100,
            duration: 1000,
        });
        this.cities.map((item, index) => {
            anime({
                targets: `.card-play-fadeIn-${index}`,
                translateX: [2080, 0],
                direction: 'alternate',
                loop: false,
                delay: 100 * index,
                endDelay: 100 * index,
                duration: 500,
            });
        })
        anime({
            targets: `.card-play-fadeIn-5`,
            translateX: [2080, 0],
            direction: 'alternate',
            loop: false,
            delay: 100 * 5,
            endDelay: 100 * 5,
            duration: 500,
        });

    }
    view() {
        return m('.container-fluid.h-100', {
            style: {
                // padding: "1% 2%",
                // backgroundImage: "linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%)"
                background: "#FFF"
            }
        }, [
            m(Dialog),

            m(".row.py-3.text-card-play.m-0", [
                m(".col-md-12.pr-md-2.col-12.px-0", [
                    m(".row.w-100.m-0", {
                        style: {
                            fontSize: "0.9rem"
                        }
                    }, [
                        m(".col-12.py-3", [
                            m(`span.btn.btn-primary.w-100.card-play-fadeIn-5.border-0.font-weight-bold`, {
                                style: {
                                    background: (this.open) ? "#C7D9A4" : "#A0A0A0",
                                    borderRadius: "15px",
                                    color: "#FFF",
                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.open = !this.open
                                }
                            }, (this.open) ? "摺疊" : "展開"),
                        ]),

                        (this.open) ? [
                            m(".col-md-6.col-12", [
                                m(".row.w-100.m-0", [
                                    this.cities.map((_item, index) => {
                                        return [
                                            m(`.col-md-2.col-6.py-2`, [
                                                m(`span.btn.btn-outline-success.w-100.card-play-fadeIn-${index}.border-0.font-weight-bold`, {
                                                    style: {
                                                        background: (window.select_cate == _item) ? "#A4D5D9" : "#DBE9EA",
                                                        borderRadius: "15px",
                                                        color: (window.select_cate == _item) ? "#FFF" : "#589BA0",
                                                    },
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                        window.select_cate = _item
                                                        window.search_word = ""
                                                        Board.fetch().then((_response) => {
                                                            this.results = _response
                                                            this.upBoder = 0
                                                            this.results.map((_data) => {
                                                                if (_data.photo == "" || _data.photo == null) {

                                                                } else {
                                                                    this.upBoder += 1
                                                                }
                                                            })
                                                        })
                                                    }
                                                }, _item)
                                            ])
                                        ]
                                    }),
                                ]),

                                m(".row.w-100.m-0.d-flex.justify-content-between", [
                                    m(".col-md-6.col-12", [
                                        m(`span.btn.btn-primary.w-100.card-play-fadeIn-5.border-0.font-weight-bold`, {
                                            style: {
                                                background: "#C7D9A4",
                                                borderRadius: "15px",
                                                color: "#FFF",
                                            },
                                            onclick: (e) => {
                                                e.preventDefault()
                                                Dialog.show(EditComponent, {
                                                    attrs: {
                                                        model: new Board(),
                                                        cities: this.cities,
                                                        types: this.types,
                                                        methods: this.methods
                                                    },
                                                    didHide: (returnValue) => {
                                                        if (returnValue) {

                                                        }
                                                    }
                                                })

                                            }
                                        }, "新增")
                                    ]),
                                    m(`.col-md-4.col-12.pt-2.d--flex.align-items-end.pb-md-3`, [
                                        m(`span.btn.btn-info.w-100.card-play-fadeIn-5.border-0.font-weight-bold`, {
                                            style: {
                                                background: "#A0A0A0",
                                                borderRadius: "15px",
                                                color: "#FFF",
                                            },
                                            onclick: (e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                window.search_word = ""
                                                window.select_cate = ""
                                                Board.fetch().then((_response) => {
                                                    this.results = _response
                                                    this.upBoder = 0
                                                    this.results.map((_data) => {
                                                        if (_data.photo == "" || _data.photo == null) {

                                                        } else {
                                                            this.upBoder += 1
                                                        }
                                                    })
                                                    window.search_word = ""
                                                    window.select_cate = ""
                                                })
                                            }
                                        }, "全部資料")
                                    ]),

                                ]),

                            ]),

                            m(`.col-md-3.d-flex.justify-content-center.align-items-center.pt-3`, [
                                m("span.badge", {
                                    style: {
                                        fontSize: "2rem",
                                        background: "#A4D5D9",
                                        color: "white"
                                    }
                                }, "目前已上架 " + this.upBoder + " 筆")
                            ]),
                            m(`.col-md-2.col-12.pt-2.d-flex.align-items-end.pb-3`, [
                                m(TextBox, {
                                    oninput: (e) => {
                                        window.search_word = e.target.value
                                    },
                                    value: window.search_word,
                                    class: ".m-0",
                                    type: 'text',
                                    label: '關鍵字',
                                }),

                            ]),
                            m(`.col-md-1.col-12.pt-2.d-flex.align-items-end.pb-3`, [
                                m(`span.btn.btn-info.w-100.card-play-fadeIn-5.border-0.font-weight-bold`, {
                                    style: {
                                        background: "#A0A0A0",
                                        borderRadius: "15px",
                                        color: "#FFF",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        Board.fetch().then((_response) => {
                                            this.results = _response
                                            window.search_word = ""
                                            window.select_cate = ""
                                            this.upBoder = 0
                                            this.results.map((_data) => {
                                                if (_data.photo == "" || _data.photo == null) {

                                                } else {
                                                    this.upBoder += 1
                                                }
                                            })
                                        })
                                    }
                                }, "查詢")
                            ]),
                            // m(`.col-md-12.pt-2.d-none.d-md-block`, [
                            //     m(`span.btn.btn-primary.w-100.card-play-fadeIn-5.border-0.font-weight-bold`, {
                            //         style: {
                            //             background: "#A4BFD9",
                            //             borderRadius: "15px",
                            //             color: "#FFF",
                            //         },
                            //         onclick: (e) => {
                            //             e.preventDefault()
                            //             e.stopPropagation()
                            //             let url = `./api/test/export`
                            //             window.location.href = url

                            //         }
                            //     }, "匯出")
                            // ]),

                        ] : "",


                        m(".col-12.d-block.d-md-none.px-0", [
                            (!Board.loading) ? [
                                this.results.map((_data, index) => {
                                    var money = 0
                                    if (_data.height * _data.width < 210600) {
                                        money = Math.ceil(_data.height * _data.width / 900) * 18 + 1200
                                    } else if (_data.height * _data.width >= 210600 && _data.height * _data.width < 270000) {
                                        money = 5400
                                    } else {
                                        money = Math.ceil(_data.height * _data.width / 900) * 18
                                    }
                                    return [
                                        m('.row.no-gutters.m-0.pt-1', [

                                            m(".col-12.px-0.pt-md-1.d-flex.justify-content-start.text-left", {
                                                style: {
                                                    borderStyle: (_data.is_later) ? "solid" : "solid",
                                                    borderWidth: (_data.is_later) ? "1px" : "0px",
                                                    borderColor: (_data.is_later) ? "#B80D0D" : "",
                                                }
                                            }, [
                                                m(`span.badge.badge-info.w-100.text-left`, {
                                                    style: {
                                                        fontSize: "1.2rem",
                                                        backgroundColor: (_data.photo == "" || _data.photo == null) ? "rgba(200,200,200,1)" : "",
                                                        overflow: "hidden",
                                                        color: (_data.is_later) ? "#B80D0D" : "",
                                                        fontWeight: (_data.is_later) ? "400" : "700"
                                                    },
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        if (this.click_index == index) {
                                                            this.click_index = 9999
                                                        } else {
                                                            this.click_index = index
                                                        }
                                                    }
                                                }, (index + 1) + '. ' + _data.city + " - " + _data.address + ((_data.subtitle) ? ("(" + _data.subtitle + ")") : ""))
                                            ]),
                                            (this.click_index == index) ?
                                                m(".col-12.pb-1.px-0", [
                                                    m(".card", [
                                                        m(".position-relative", [
                                                            (_data.photo == "" || _data.photo == null) ?
                                                                m(".py-2") :
                                                                m("img.img-fluid", {
                                                                    src: window.BASE_URL + "/images/boards/" + _data.photo,
                                                                    style: {
                                                                        maxHeight: "160px"
                                                                    },
                                                                    onclick: (e) => {
                                                                        e.preventDefault()
                                                                        Dialog.show(PhotoComponent, {
                                                                            attrs: {
                                                                                photo: _data.photo,
                                                                                type: "photo"
                                                                            },
                                                                            didHide: (returnValue) => {
                                                                                if (returnValue) {
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                }),
                                                            (_data.phototwo == "" || _data.phototwo == null) ?
                                                                m(".py-2") :
                                                                [
                                                                    m(".py-1"),
                                                                    m("img.img-fluid", {
                                                                        src: window.BASE_URL + "/images/boards/" + _data.phototwo,
                                                                        style: {
                                                                            maxHeight: "160px"
                                                                        },
                                                                        onclick: (e) => {
                                                                            e.preventDefault()
                                                                            Dialog.show(PhotoComponent, {
                                                                                attrs: {
                                                                                    photo: _data.phototwo,
                                                                                    type: "photo"
                                                                                },
                                                                                didHide: (returnValue) => {
                                                                                    if (returnValue) {
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }),
                                                                ],
                                                            // (_data.is_car) ?
                                                            //     m(".position-absolute", {
                                                            //         style: {
                                                            //             top: "5px",
                                                            //             left: "5px"
                                                            //         }
                                                            //     }, [
                                                            //         m("span.badge.badge-danger.px-2", {
                                                            //             style: {
                                                            //                 fontSize: "1.1rem"
                                                            //             }
                                                            //         }, "吊車")

                                                            //     ]) : "",
                                                            m(".position-absolute", {
                                                                style: {
                                                                    top: "5px",
                                                                    right: "5px",
                                                                    fontSize: "1.5rem"
                                                                }
                                                            }, [
                                                                m("span.badge.badge-warning.px-2", { style: { backgroundColor: "#FFEFA6" } }, _data.height + " * " + _data.width),
                                                                m(".px-1"),
                                                                m("span.badge.badge-warning.px-2", { style: { backgroundColor: "#FFEFA6" } }, Math.ceil(_data.height * _data.width / 900) + ' 才'),
                                                                m(".px-1"),

                                                                (_data.height * _data.width >= 270000) ?
                                                                    m("span.badge.badge-danger.px-2", { style: { backgroundColor: "#E98873" } }, "足才") :
                                                                    m("span.badge.badge-danger.px-2", { style: { backgroundColor: "#E98873" } }, "不足才"),
                                                                (_data.height * _data.width >= 210600 && _data.height * _data.width < 270000) ?
                                                                    m("span.badge.badge-danger.px-2.d-block", { style: { backgroundColor: "#E98873" } }, "以300才計算") :
                                                                    "",
                                                                m("span.badge.badge-danger.px-2.d-block", { style: { backgroundColor: "#6DE0E5" } }, "$ " + money)
                                                            ])
                                                        ]),

                                                        m(".card-body.pb-2.px-2.pt-2.position-relative", {
                                                            style: {
                                                                fontSize: "1rem"
                                                            }
                                                        }, [
                                                            m("span.d-block", "方式：" + _data.method),
                                                            // m("span.d-block", "地址：" + ((_data.subtitle) ? _data.subtitle : "無")),
                                                            m("span.d-block", "聯絡：" + _data.contact),
                                                            m("span.d-block", "電話：" + ((_data.phone) ? _data.phone : "")),
                                                            m("span.d-block", "吊車：" + ((_data.is_car) ? "是" : "否")),
                                                            m("span.d-block", "廠商：" + _data.company),
                                                            m("span.d-block", "鷹架：" + ((_data.is_eagle) ? "是" : "否")),
                                                            m("span.d-block", "租金：" + _data.rent),
                                                            (_data.is_paid_one) ? m("span.d-block", "第一次付款：是") : "",
                                                            (_data.is_paid_two) ? m("span.d-block", "第二次付款：是") : "",
                                                            m("span.d-block", "備註：" + ((_data.memo) ? _data.memo : "")),
                                                            m("span.d-block", "版面內容：" + ((_data.title) ? _data.title : "")),

                                                            m(".d-flex.justify-content-end", {
                                                                // style: {
                                                                //     bottom: "5px",
                                                                //     right: "5px"
                                                                // },
                                                            },
                                                                m("button.btn.btn-danger.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button'].border-0", {
                                                                    style: {
                                                                        background: (_data.is_later) ? "#C7D9A4" : "E6AAAA",
                                                                        color: "#FFF",
                                                                        height: "45px",
                                                                        width: "45px"
                                                                    },
                                                                    onclick: (e) => {
                                                                        e.preventDefault()
                                                                        _data.is_later = !_data.is_later
                                                                        m.redraw()
                                                                        Board.changeStatus(_data.id).then(() => {
                                                                        })
                                                                    }
                                                                },
                                                                    [
                                                                        (_data.is_later) ?
                                                                            m("span", "正") :
                                                                            m("span", "延"),
                                                                    ]
                                                                ),
                                                                m(".px-2"),
                                                                m("img.img-fluid", {
                                                                    src: window.BASE_URL + "/img/google.png",
                                                                    style: {
                                                                        maxHeight: "55px",
                                                                        maxWidth: "55px"
                                                                    },
                                                                    onclick: (e) => {
                                                                        e.preventDefault()
                                                                        let _url = ""
                                                                        if (_data.type == "地址") {
                                                                            _url = "https://www.google.com/maps/search/" + _data.city + _data.address
                                                                        } else {
                                                                            _url = "https://www.google.com/maps/search/" + _data.city + _data.address.replace('與', ' ')
                                                                        }
                                                                        window.open(_url)
                                                                    }
                                                                }),
                                                                m(".px-2"),

                                                                m("button.btn.btn-primary.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button'].border-0", {
                                                                    style: {
                                                                        background: "#316A38",
                                                                        color: "#FFF",
                                                                        height: "45px",
                                                                        width: "45px"
                                                                    },
                                                                    onclick: (e) => {
                                                                        e.preventDefault()
                                                                        Dialog.show(EditComponent, {
                                                                            attrs: {
                                                                                model: new Board(_data),
                                                                                cities: this.cities,
                                                                                types: this.types,
                                                                                methods: this.methods
                                                                            },
                                                                            didHide: (returnValue) => {
                                                                                if (returnValue) {
                                                                                    Board.fetch().then((_response) => {
                                                                                        this.results = _response
                                                                                        this.upBoder = 0
                                                                                        this.results.map((_data) => {
                                                                                            if (_data.photo == "" || _data.photo == null) {

                                                                                            } else {
                                                                                                this.upBoder += 1
                                                                                            }
                                                                                        })
                                                                                    })
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                },
                                                                    [
                                                                        m("i.fa.fa-edit"),

                                                                    ]
                                                                ),
                                                            ),
                                                        ])
                                                    ])
                                                ]) : ""
                                        ])
                                    ]
                                }),] : m(".col-12.text-center.d-flex.justify-content-center.align-items-center", {
                                    style: {
                                        minHeight: "80vh"
                                    }
                                }, [
                                    m(".spinner")
                                ]),

                        ]),
                    ])
                ]),
                m(".col-md-12.d-none.d-md-block", [
                    m(".row.w-100.m-0", [
                        m(".col-12.p-0", [
                            m(".row.w-100.py-2.font-weight-bold.m-0", {
                                style: {
                                    background: "#A4D5D9",
                                    color: "white"
                                }
                            }, [
                                m(".col.px-0.text-center", {
                                    style: {
                                        maxWidth: "2%"
                                    },
                                },
                                    "#"
                                ),
                                m(".col.px-0.text-center",
                                    "地區"
                                ),
                                m(".col.px-0.text-center", {
                                    style: {
                                        minWidth: "15%"
                                    },
                                },
                                    "地址"
                                ),
                                // m(".col.px-0",
                                //     "備註"
                                // ),
                                m(".col.px-0.text-center",
                                    "連結"
                                ),
                                m(".col.px-0.text-center",
                                    "照片1"
                                ),
                                m(".col.px-0.text-center",
                                    "照片2"
                                ),
                                m(".col.px-0.text-center",
                                    "廠商"
                                ),
                                m(".col.px-0.text-center",
                                    "鷹架"
                                ),
                                m(".col.px-0.text-center",
                                    "尺寸"
                                ),
                                m(".col.px-0.text-center", {
                                    style: {
                                        minWidth: "10%"
                                    },
                                },
                                    "裁數"
                                ),
                                m(".col.px-0.text-center",
                                    "吊車"
                                ),
                                m(".col.px-0.text-center",
                                    "方式"
                                ),
                                // m(".col.px-0",
                                //     "窗口"
                                // ),
                                // m(".col.px-0",
                                //     "電話"
                                // ),
                                m(".col.px-0.text-center",
                                    "租金"
                                ),
                                m(".col.px-0.text-center",
                                    "窗口"
                                ),
                                m(".col.px-0.text-center",
                                    "電話"
                                ),
                                m(".col.px-0.text-center",
                                    "備註"
                                ),
                                // m(".col.px-0", {
                                //     style: {
                                //         minWidth: "10%"
                                //     },
                                // },
                                //     "版面內容"
                                // ),
                                m(".col.px-0.text-center", {
                                    style: {
                                        minWidth: "10%"
                                    },
                                },
                                    "編輯"
                                ),

                            ])
                        ]),

                        (!Board.loading) ? [
                            this.results.map((_data, index) => {
                                var money = 0
                                if (_data.height * _data.width < 210600) {
                                    money = Math.ceil(_data.height * _data.width / 900) * 18 + 1200
                                } else if (_data.height * _data.width >= 210600 && _data.height * _data.width < 270000) {
                                    money = 5400
                                } else {
                                    money = Math.ceil(_data.height * _data.width / 900) * 18
                                }
                                return m(`.col-12.px-0`, {
                                    style: {
                                        paddingBottom: (_data.is_later) ? "1px" : ""
                                    }
                                },

                                    m(".row.py-1.font-weight-bold.d-flex.align-items-center.m-0", {
                                        style: {
                                            background: (index % 2 == 0) ? "#E8E8E8" : "#F4F4F4",
                                            color: "#707070",
                                            fontSize: "0.9rem",
                                            borderStyle: (_data.is_later) ? "solid" : "solid",
                                            borderWidth: (_data.is_later) ? "1px" : "0px",
                                            borderColor: (_data.is_later) ? "#B80D0D" : "",
                                        },
                                    }, [

                                        m(".col.px-0.text-center", {
                                            style: {
                                                maxWidth: "2%",
                                                color: (_data.is_later) ? "#B80D0D" : ""
                                            },
                                        },
                                            index + 1
                                        ),
                                        m(".col.px-0.text-center",
                                            _data.city
                                        ),
                                        m(".col.px-0.text-center", {
                                            style: {
                                                minWidth: "15%"
                                            },
                                        },
                                            _data.address + ((_data.subtitle == "" || _data.subtitle == null) ? "" : ("(" + _data.subtitle + ")"))
                                        ),
                                        m(".col.px-0.d-flex.justify-content-center.align-items-center.text-center",
                                            m("img.img-fluid", {
                                                src: window.BASE_URL + "/img/google.png",
                                                style: {
                                                    maxHeight: "40px",
                                                    maxWidth: "40px"
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    let _url = ""
                                                    if (_data.type == "地址") {
                                                        _url = "https://www.google.com/maps/search/" + _data.city + _data.address
                                                    } else {
                                                        _url = "https://www.google.com/maps/search/" + _data.city + _data.address.replace('與', ' ')
                                                    }
                                                    window.open(_url)
                                                }
                                            }),
                                        ),
                                        m(".col.px-0.d-flex.justify-content-center.align-items-center.text-center",
                                            (_data.photo == "" || _data.photo == null) ?
                                                "" :
                                                m("img.img-fluid", {
                                                    src: window.BASE_URL + "/img/jpg.png",
                                                    style: {
                                                        maxHeight: "60px",
                                                        maxWidth: "40px"
                                                    },
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        Dialog.show(PhotoComponent, {
                                                            attrs: {
                                                                photo: _data.photo,
                                                                type: "photo"
                                                            },
                                                            didHide: (returnValue) => {
                                                                if (returnValue) {
                                                                }
                                                            }
                                                        })
                                                    }
                                                }) ,
                                        ),
                                        m(".col.px-0.d-flex.justify-content-center.align-items-center.text-center",
                                            (_data.phototwo == "" || _data.phototwo == null) ?
                                                "" :
                                                m("img.img-fluid", {
                                                    src: window.BASE_URL + "/img/jpg.png",
                                                    style: {
                                                        maxHeight: "60px",
                                                        maxWidth: "40px"
                                                    },
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        Dialog.show(PhotoComponent, {
                                                            attrs: {
                                                                photo: _data.phototwo,
                                                                type: "photo"
                                                            },
                                                            didHide: (returnValue) => {
                                                                if (returnValue) {
                                                                }
                                                            }
                                                        })
                                                    }
                                                }),
                                        ),
                                        m(".col.px-0.text-center",
                                            {
                                                style: {
                                                    color: (_data.company == "優是") ? "#7C7AE8" : "",
                                                    fontSize: "1rem"
                                                }
                                            },
                                            _data.company
                                        ),
                                        m(".col.px-0.text-center",
                                            (_data.is_eagle) ?
                                                m("span", {
                                                    style: {
                                                        color: "#337C03",
                                                        fontSize: "1.5rem",
                                                    }
                                                }, "V")
                                                : ""
                                        ),
                                        m(".col.px-0.text-center",
                                            _data.height + " * " + _data.width
                                        ),
                                        m(".col.px-0.text-center", {
                                            style: {
                                                minWidth: "10%"
                                            },
                                        },

                                            m("span", {
                                                style: {
                                                    // color: "#337C03",
                                                    fontSize: "1rem",
                                                }
                                            }, Math.ceil(_data.height * _data.width / 900) + " 才"),
                                            m("span.pl-1", {
                                                style: {
                                                    color: (_data.height * _data.width > 270000) ? "#337C03" : "#B4BBB1",
                                                    fontSize: "1rem",
                                                }
                                            }, (_data.height * _data.width > 270000) ? "(足)" : "(不足)"),
                                            (_data.height * _data.width >= 210600 && _data.height * _data.width < 270000) ?
                                                m("span.badge.badge-danger.px-2.d-block", { style: { backgroundColor: "#E98873" } }, "以300才計算") :
                                                ""

                                        ),
                                        m(".col.px-0.text-center",
                                            (_data.is_car) ?
                                                m("span", {
                                                    style: {
                                                        color: "#337C03",
                                                        fontSize: "1.5rem",
                                                    }
                                                }, "V")
                                                : ""
                                        ),
                                        m(".col.px-0.text-center",
                                            _data.method
                                        ),
                                        // m(".col.px-0",
                                        //     _data.contact
                                        // ),
                                        // m(".col.px-0",
                                        //     _data.phone
                                        // ),
                                        m(".col.px-0.text-center",
                                            _data.rent
                                        ),
                                        m(".col.px-0.text-center",
                                            {
                                                style: {
                                                    minWidth: "6%"
                                                },
                                            },
                                            // (_data.is_paid_one) ?
                                            //     m("span", {
                                            //         style: {
                                            //             color: "#337C03",
                                            //             fontSize: "1.5rem",
                                            //         }
                                            //     }, "V")
                                            //     : ""
                                            m("p", {
                                                style: {
                                                    wordWrap: "pre-wrap"

                                                }
                                            }, _data.contact)
                                        ),
                                        m(".col.px-0.text-center",
                                            {
                                                style: {
                                                    minWidth: "6%"
                                                },
                                            },
                                            // (_data.is_paid_two) ?
                                            //     m("span", {
                                            //         style: {
                                            //             color: "#337C03",
                                            //             fontSize: "1.5rem",
                                            //         }
                                            //     }, "V")
                                            //     : ""
                                            _data.phone
                                        ),
                                        m(".col.px-0.text-center",
                                            _data.memo
                                        ),
                                        m(".col.px-0.text-center",
                                            m("span.", "$ " + money)
                                        ),




                                        // m(".col.px-0", {
                                        //     style: {
                                        //         minWidth: "10%"
                                        //     },
                                        // },
                                        //     _data.title
                                        // ),

                                        m(".col.px-0.text-center", {
                                            style: {
                                                minWidth: "10%"
                                            },
                                        },
                                            [

                                                m("button.btn.btn-primary.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button'].border-0.px-2", {
                                                    style: {
                                                        background: "#316A38",
                                                        color: "#FFF",
                                                        width: "50px"
                                                    },
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        Dialog.show(EditComponent, {
                                                            attrs: {
                                                                model: new Board(_data),
                                                                cities: this.cities,
                                                                types: this.types,
                                                                methods: this.methods
                                                            },
                                                            didHide: (returnValue) => {
                                                                if (returnValue) {
                                                                    Board.fetch().then((_response) => {
                                                                        this.results = _response
                                                                        this.upBoder = 0
                                                                        this.results.map((_data) => {
                                                                            if (_data.photo == "" || _data.photo == null) {

                                                                            } else {
                                                                                this.upBoder += 1
                                                                            }
                                                                        })
                                                                    })
                                                                }
                                                            }
                                                        })
                                                    }
                                                },
                                                    [
                                                        m("span", "編輯"),

                                                    ]
                                                ),
                                                m("button.btn.btn-danger.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button'].border-0.px-2", {
                                                    style: {
                                                        background: (!_data.is_later) ? "#C7D9A4" : "E6AAAA",
                                                        color: "#FFF"
                                                    },
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        Board.changeStatus(_data.id).then(() => {
                                                            _data.is_later = !_data.is_later
                                                        })
                                                    }
                                                },
                                                    [
                                                        (!_data.is_later) ?
                                                            m("span", "正") :
                                                            m("span", "延"),
                                                    ]
                                                ),
                                                m("button.btn.btn-danger.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button'].border-0", {
                                                    style: {
                                                        background: "#E6AAAA",
                                                        color: "#FFF"
                                                    },
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        swal({
                                                            title: "是否確認刪除",
                                                            text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                            icon: "warning",
                                                            dangerMode: true,
                                                            buttons: ["取消", "確認"]
                                                        })
                                                            .then((willDelete) => {
                                                                if (willDelete) {
                                                                    Board.delete(_data.id).then(() => {
                                                                        swal("您的資料已成功刪除", {
                                                                            icon: "success",
                                                                            buttons: {
                                                                                cancel: false,
                                                                                confirm: "確認",
                                                                            },
                                                                        }).then((response) => {
                                                                            Board.fetch().then((_response) => {
                                                                                this.results = _response
                                                                                this.upBoder = 0
                                                                                this.results.map((_data) => {
                                                                                    if (_data.photo == "" || _data.photo == null) {

                                                                                    } else {
                                                                                        this.upBoder += 1
                                                                                    }
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                }
                                                            });
                                                    }
                                                },
                                                    [
                                                        m("i.fa.fa-trash"),
                                                    ]
                                                )
                                            ]
                                        ),
                                    ])
                                )
                            })
                            ,] : m(".col-12.text-center.d-flex.justify-content-center.align-items-center", {
                                style: {
                                    minHeight: "80vh"
                                }
                            }, [
                                m(".spinner")
                            ]),
                    ]),
                    m("table.table.table-hover.pt-3.border.mb-0",
                        [
                            m("thead",
                                {
                                    style: {
                                        background: "#A4D5D9",
                                        color: "white"
                                    }
                                },
                                m("tr",
                                    [

                                    ]
                                )
                            ),
                            m("tbody",
                                [

                                ]
                            )
                        ]
                    ),
                ])
            ]),
        ])
    }
}