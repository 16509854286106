import m from 'mithril'
import stream from 'mithril/stream'
import moment from 'moment'
import {
    Dialog,
    TextBox,
    TextArea,
} from '@base/app/components'

import {
    PhotoUploader,
} from '@base/app/components/uploader'



class PhotoComponent {
    constructor(vnode) {
        this.photo = vnode.attrs.photo
    }
    oncreate(){
        
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view() {
        return [m(".modal-dialog[role='document'].modal-dialog-centered", [
            m(".modal-content",
                [
                    m(".modal-body.p-0",
                        m("img.img-fluid.p-1", {
                            src: window.BASE_URL + "/images/boards/" + this.photo
                        }),
                        m(".py-2",[
                            m("button.btn.btn-label.rounded.w-100.mx-0", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                m("span", "關閉")
                            ),
                        ])
                        
                    ),
                ]
            )
        ])]
    }
}

export default PhotoComponent