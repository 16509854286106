import m from 'mithril'
import uuid from 'uuid'
import stream from 'mithril/stream'
import moment from 'moment'
import BaseModel from './_base'

let _data = []
const constraints = {

}

class Board extends BaseModel {
    
    constructor(args) {
        super(constraints)
        args = (args) ? args : {}
        this.id = args.id || 0
        this.city = args.city || "土城區"
        this.address = args.address || ""
        this.subtitle = args.subtitle || ""
        this.type = args.type || "地址"
        this.company = args.company || ""
        this.photo = args.photo || ""
        this.phototwo = args.phototwo || ""
        this.height = args.height || 0
        this.width = args.width || 0
        this.is_car = args.is_car || false
        this.is_later = args.is_later || false
        this.is_eagle = args.is_eagle || false
        this.is_paid_one = args.is_paid_one || false
        this.is_paid_two = args.is_paid_two || false
        this.method = args.method || "牆面"
        this.contact = args.contact || ""
        this.phone = args.phone || ""
        this.rent = args.rent || 0
        this.memo = args.memo || ""
        this.title = args.title || ""
    }


    static get data() {
        return _data;
    }

    static fetch() {
        Board.loading = true
        return m.request({
            method: 'GET',
            url: `/api/board?cate=${window.select_cate}&search=${window.search_word}`
        }).then((response) => {
            Board.loading = false
            return response
        })
    }

    static changeStatus(id) {
        // Board.loading = true
        return m.request({
            method: 'get',
            url: `${window.BASE_URL}/api/board/changeStatus/${id}`,
        }).then((response) => {
            // Board.loading = false
        })
    }

    

    save() {
        Board.loading = true
        return m.request({
            method: 'POST',
            url: `/api/board`,
            body: this
        }).then((response)=>{
            Board.loading = false
        })
    }

    static delete(id) {
        Board.loading = true
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/board/${id}`,
        }).then((response) => {
            Board.loading = false
        })
    }
}

export default Board